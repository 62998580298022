import { format, parse } from 'date-fns';
import { getNextDay } from '../../../helpers/getNextDay';
import {
  allDatesFormat,
  dateTimeFallback,
  formatToAmPm,
  getTimeFormat,
  convertTimeObject,
  convertTo24HourFormat,
} from 'common/dateFormatConfig';

export const getWeekends = (user) => {
  if (!user) return [];
  const userWorkDays = user.profile.shifts.weekDays;
  switch (userWorkDays) {
    case 'Sun-Thu':
      return [5, 6];
    case 'Mon-Fri':
      return [0, 6];
    case 'Tue-Sat':
      return [0, 1];
    default:
      return [];
  }
};

export const formatData = ({ values, user }, dateFormat = dateTimeFallback, getTimeFormatHook) => {
  const startHoursObj = [
    values.startDate,
    values.startHours,
    values.startMinutes,
    values.startAmPm,
  ];
  const startDate = parse(
    getTimeFormatHook(startHoursObj).formattedTimeDate,
    getTimeFormatHook(startHoursObj).formatForDateTimePars,
    new Date()
  );

  const start24Hours = getTimeFormatHook().is12Format
    ? +convertTo24HourFormat(values.startHours, values.startAmPm)
    : +values.startHours;
  const end24Hours = (
    ((start24Hours + values.durationHours + Math.floor((+values.startMinutes + values.durationMinutes) / 60)) % 24)
  )

  const endHoursObj = values.type === 'full'
    ? [values.endDate, values.endHours, values.endMinutes, values.endAmPm]
    : [
      values.endDate,
      `${getTimeFormatHook().is12Format ? (end24Hours % 12 || 12) : end24Hours}`.padStart(2, '0'),
      `${(+values.startMinutes + values.durationMinutes) % 60}`.padStart(2, '0'),
      end24Hours >= 12 ? 'pm' : 'am',
    ]

  const endDate = parse(
    getTimeFormatHook(endHoursObj).formattedTimeDate,
    getTimeFormatHook(endHoursObj).formatForDateTimePars,
    new Date()
  );

  return {
    type: values.type,
    userId: user._id,
    isPTO: values.isPTO,
    reason: values.reason,
    notes: values.notes,
    relativeName: values.relativeName,
    relationship: values.relationship,
    utcStartDate: startDate.toISOString(),
    startTime: {
      hour: values.startHours,
      minute: values.startMinutes,
      amPm: values?.startAmPm,
    },
    endTime: {
      hour: values.endHours,
      minute: values.endMinutes,
      amPm: values?.endAmPm,
    },
    utcEndDate: endDate.toISOString(),
  };
};

export const reasons = ['Vacation Day', 'Sick Leave', 'Bereavement', 'FMLA', 'Other'];
export const relations = ['Child', 'Grand-Parent', 'Parent', 'Sibling', 'Spouse', 'Other'];

export const getDefaultFormValues = ({ pto, shiftTime, dateFormat, is12Format }) => {
  const todayDate = new Date();
  const today = format(todayDate, dateFormat);
  const tomorrow = getNextDay(todayDate, dateFormat);

  const { hour, minute, amPm } = convertTimeObject(shiftTime, is12Format);

  return {
    notes: '',
    isPTO: !!(pto && pto.available),
    type: 'full',
    reason: reasons[0],
    startDate: today,
    startHours: String(Number(hour)),
    startMinutes: minute,
    startAmPm: amPm || formatToAmPm(hour),
    endDate: tomorrow,
    endHours: String(Number(hour)),
    endMinutes: minute,
    endAmPm: amPm || formatToAmPm(hour),
    relationship: '',
    hours: 4,
    durationHours: 4,
    durationMinutes: 0,
  };
};
