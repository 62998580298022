import { createReducer } from '@reduxjs/toolkit';
import timeclocksPageActions from './timeclocksPageActions';

const initialState = {
  // user: null,
  loading: false,
  dis: false,
  forceUpdate: false,
  users: [],
  allUsers: [],
  summary: {
    data: [],
    query: {},
    exported: null,
    page: 0,
    hasMore: false,
  },
  weekSummary: {
    data: {},
    query: {},
    exported: null,
  },
  activeTab: 3,
  tabs: [
    {
      index: 0,
      name: 'all',
      label: 'All reports',
      href: '/timeclock/all',
      hidden: true,
    },
    {
      index: 1,
      name: 'submitted',
      label: 'Submitted',
      href: '/timeclock/submitted',
    },
    {
      index: 2,
      name: 'machine-shop',
      label: 'Machine shop',
      href: '/timeclock/machine-shop',
    },
    {
      index: 3,
      name: 'by-individual-week',
      label: 'By individual week',
      href: '/timeclock/by-individual-week',
    },
  ],
  notifications: [],
};

const timeclocks = createReducer(initialState, {
  [timeclocksPageActions.setTabs]: (state, { payload }) => ({ ...state, tabs: payload }),
  [timeclocksPageActions.setActiveTab]: (state, { payload }) => ({
    ...state,
    activeTab: payload >= 0 ? payload : 3,
    summary: { ...state.summary, data: initialState.summary.data, page: 0 },
    weekSummary: {
      ...state.weekSummary,
      data: initialState.weekSummary.data,
    },
  }),
  [timeclocksPageActions.setLoading]: (state, { payload }) => ({ ...state, loading: payload }),
  [timeclocksPageActions.dis]: (state, { payload }) => ({ ...state, dis: payload }),
  // [timeclocksPageActions.setUser]: (state, { payload }) => ({ ...state, user: payload }),
  [timeclocksPageActions.setWeekSummary]: (state, { payload }) => ({
    ...state,
    weekSummary: payload,
  }),
  [timeclocksPageActions.setSummary]: (state, { payload }) => ({ ...state, summary: payload }),
  [timeclocksPageActions.setUsers]: (state, { payload }) => ({ ...state, users: payload }),
  [timeclocksPageActions.setAllUsers]: (state, { payload }) => ({ ...state, allUsers: payload }),
  [timeclocksPageActions.setCurrentPage]: (state, { payload }) => ({
    ...state,
    summary: { ...state.summary, page: payload },
  }),

  [timeclocksPageActions.setPayrollVerified]: (state, { payload }) => ({
    ...state,
    weekSummary: {
      ...state.weekSummary,
      data: {
        ...state.weekSummary.data,
        summaries: (state.weekSummary.data.summaries || []).map((item) => {
          if (item.date === payload.date) {
            return {
              ...item,
              summary: {
                ...(item.summary || {}),
                payroll: payload.data,
              },
            };
          }
          return item;
        }),
      },
    },
  }),

  [timeclocksPageActions.setPayrollReconciled]: (state, { payload }) => ({
    ...state,
    weekSummary: {
      ...state.weekSummary,
      data: {
        ...state.weekSummary.data,
        summaries: (state.weekSummary.data.summaries || []).map((item) => {
          if (item.date === payload.date) {
            return {
              ...item,
              summary: {
                ...(item.summary || {}),
                payroll: payload.data,
              },
            };
          }
          return item;
        }),
      },
    },
  }),

  [timeclocksPageActions.setExcelLoading]: (state, { payload }) => ({ ...state, excelLoading: payload }),
  [timeclocksPageActions.reset]: (state, { payload }) => ({ ...initialState }),

  [timeclocksPageActions.enqueueSnackbar]: (state, { payload }) => {

    return{
    ...state,
    notifications: [
      ...state.notifications,
      {
        ...payload,
      },
      // {
      //   key: payload.key,
      //   ...payload.notification,
      // },
    ],
  }},
  [timeclocksPageActions.removeSnackbar]: (state, { payload }) => ({
    ...state,
    notifications: state.notifications.filter((notification) => notification.key !== payload.key),
  }),
  [timeclocksPageActions.setForceUpdate]: (state, { payload }) => ({
    ...state,
    forceUpdate: payload,
  }),
  [timeclocksPageActions.removeCipJob]: (state, { payload }) => ({
    ...state,
    weekSummary: {
      ...state.weekSummary,
      data: {
        ...state.weekSummary.data,
        summaries: (state.weekSummary.data.summaries || []).map((item) => {
          if (item.date === payload.date) {
            return {
              ...item,
              summary: {
                ...(item.summary || {}),
                cipJobs: item.summary?.cipJobs?.filter(cipJob => cipJob.cipProject._id !== payload.cipProjectId),
              },
            };
          }
          return item;
        }),
      },
    },
  })
});

export default timeclocks;
