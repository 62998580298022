import React, { useEffect, useMemo } from 'react';
import CipField from './CipField';
import CipButton from '@material-ui/core/Button';
import useStyles from '../useStyles';
import { Button, DialogActions } from '../../base';
import { Formik, Form, Field, FieldArray } from 'formik';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { createTimeclockCipHours, getCipProjects, updateTimeclockCipHours } from 'store/cip/cipPagesOperations';

const initCipJob = {
  cipProjectId: '',
  time: {
    hours: 0,
    minutes: 0,
  },
};

const CipForm = ({ onClose, date, data, isEdit }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const cipProjects = useSelector((state) => (state.cip?.projects?.data || []));

  const cipProjectsOptions = useMemo(() => cipProjects.map(project => ({ value: project._id, label: project.name })), [cipProjects])

  const hoursOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((value) => ({ value }));
  const minutesOptions = [
    { value: 0, label: '00' },
    { value: 15, label: '15' },
    { value: 30, label: '30' },
    { value: 45, label: '45' }
  ];

  useEffect(() => {
    dispatch(getCipProjects());
  }, [])

  const onSubmit = (values) => {
    if (values) {
      if (isEdit) {
        const updateData = values.cipJobs.map((item, index) => ({
          _id: data?.cipHours?.[index]?._id,
          cipProjectId: item.cipProjectId,
          hours: item.time.hours + (item.time.minutes / 60),
        }));

        dispatch(updateTimeclockCipHours(updateData));
      } else {
        const userId = data?.user?._id;
        const cipData = values.cipJobs.map((el) => {
          const minute = el.time.minutes / 60;
          const hours = el.time.hours + minute;
          const cipProjectId = el.cipProjectId;

          return {
            userId,
            date,
            hours,
            cipProjectId,
          };
        });

        dispatch(createTimeclockCipHours(date, { data: cipData }))
      }
    }

    onClose();
  }

  return (
    <div className={classes.form}>
      <Formik
        initialValues={{
          cipJobs: (isEdit && data)
            ? data.cipHours?.map((item) => (
              { ...item, time: { hours: Math.floor(item.hours), minutes: Math.round((item.hours * 60) % 60) } }
            ))
            : [initCipJob],
        }}
        onSubmit={onSubmit}
        render={({ values }) => (
          <Form>
            <FieldArray
              name="cipJobs"
              render={(arrayHelpers) => {
                return (
                  <div>
                    {values.cipJobs.map((job, index) => (
                      <React.Fragment key={index}>
                        <Grid
                          container
                          alignItems={'center'}
                          spacing={2}
                          style={{ marginBottom: '20px' }}
                        >
                          <Grid item xs={12}>
                            <Field
                              name={`cipJobs.${index}.cipProjectId`}
                              value={job.cipProjectId}
                              fullWidth
                            >
                              {(props) => (
                                <CipField
                                  {...props}
                                  id={`cipJobs.${index}.cipProjectId`}
                                  options={cipProjectsOptions}
                                  label="Project name:"
                                />
                              )}
                            </Field>
                          </Grid>

                          <Grid
                            item
                            xs={6}
                          >
                            <Field
                              name={`cipJobs.${index}.time.hours`}
                              value={job.time?.hours || 0}
                            >
                              {(props) => (
                                <CipField
                                  {...props}
                                  id={`cipJobs.${index}.time.hours`}
                                  disabled={!job.cipProjectId}
                                  options={hoursOptions}
                                  label="Hours"
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                          >
                            <Field
                              name={`cipJobs.${index}.time.minutes`}
                              value={job?.time?.minutes || 0}
                            >
                              {(props) => (
                                <CipField
                                  {...props}
                                  disabled={!job.cipProjectId}
                                  id={`cipJobs.${index}.time.minutes`}
                                  options={minutesOptions}
                                  label="Minutes"
                                />
                              )}
                            </Field>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    ))}

                    {!isEdit && (
                      <div className={classes.timeContainer}>
                        <CipButton
                          className={classes.CipButton}
                          disabled={values.cipJobs?.length === 1}
                          onClick={() => arrayHelpers.pop()}
                        >
                          <span
                            className={classes.CipButtonText}
                            style={{
                              fontSize: ' 38px',
                              fontWeight: '100',
                              marginRight: '5px',
                            }}
                          >
                            -
                          </span>
                          <span className={classes.CipButtonText}>Remove CIP</span>
                        </CipButton>
                        <CipButton
                          className={classes.CipButton}
                          onClick={() => arrayHelpers.push({ cipProjectId: '', time: { hours: 0, minutes: 0 } })}
                        >
                          <span
                            className={classes.CipButtonText}
                            style={{
                              fontSize: ' 30px',
                              marginRight: '8px',
                              fontWeight: '100',
                            }}
                          >
                            +
                          </span>
                          <span className={classes.CipButtonText}>Add CIP</span>
                        </CipButton>
                      </div>
                    )}
                  </div>
                );
              }}
            ></FieldArray>
            <DialogActions disableSpacing={false} style={{ justifyContent: 'center' }}>
              <Button
                type="submit"
                variant={'outlined'}
                fullWidth={true}
                style={{ width: '140px' }}
              >
                Submit
              </Button>
            </DialogActions>
          </Form>
        )}
      ></Formik>
    </div>
  );
};
export default CipForm;
