import React, { useState } from 'react';
import CipForm from '../AddCipHoursModal/components/CipForm';
import { CloseButton, Dialog } from '../base';
import { DialogTitle } from '../base/styled';
import useStyles from '../AddCipHoursModal/useStyles';

const EditCipHoursModal = ({ data, onClose, open }) => {
  const classes = useStyles();
  const [touched, setTouched] = useState(false);

  return (
    <Dialog open={open}>
      <CloseButton onClick={() => onClose()} />
      <DialogTitle className={classes.title} disableTypography={true}>
        Edit CIP Hours
      </DialogTitle>
      <CipForm onClose={onClose} data={data} isEdit={true} />
    </Dialog>
  );
};

export default EditCipHoursModal;
