import React, { useState, useCallback, useEffect } from 'react';
import cs from 'classnames';
import './styles.scss';
import '../../../../styles/base/definition-list.scss';
import Worklog from './components/Worklog';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import NoDataPayroll from '../NoDataPayroll';
import NoData from '../NoData';
import DeleteModal from '../../modals/DeleteModal';
import Grid from '@material-ui/core/Grid';
import { hasPermissionsFor } from '../../../../helpers/_helpers';
import styles from './components/Worklog/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  verifySummary,
  reconcilePayroll,
  deleteLunchTime,
} from 'store/timeclocks/timeclocksPageOperations';
import useNotifier from 'store/timeclocks/useNotifiler';
import { getPayrollReconcileState, getPayrollVerifyState } from './payroll-state.helper';
import { useNavigate, useParams } from 'react-router-dom';
import { format, parse, isValid } from 'date-fns';
import { GLOBAL_DATE_FORMAT, globalBEDateFormat } from 'common/dateFormatConfig';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';

const CustomCheckbox = withStyles({
  root: {
    color: '#5A87EF',
    padding: '0px',
    '&$checked': {
      color: '#5A87EF',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const DayReport = ({
  userTitle,
  editable = false,
  data,
  date,
  currentDate,
  onRemoveTimeclock,
  onChangeTimeclock,
  onCreateTimeclock,
  onChangeLunchTime,
  worklog,
  className,
  kiosk,
  classes,
  ...props
}) => {
  const allUsers = useSelector((state) => state.people.users);
  const { users } = useSelector((state) => state.timeclocks);
  const [editMode, setEditMode] = useState(false);
  const [addCipHours, setAddCipHours] = useState(null);
  const [hasPayroll, setHasPayroll] = useState(false);
  const [payrollVerified, setPayrollVerified] = useState(false);
  const [openVerifyModal, setOpenVerifyModal] = useState(null);
  const [openReconcileModal, setOpenReconcileModal] = useState(null);
  const [payrollLastActionBy, setPayrollLastActionBy] = useState('');
  const [payrollLastActionAt, setPayrollLastActionAt] = useState('');
  const { dateFormatWithTime } = useDateSettingsFormat();
  const [addMoreHours, setAddMoreHours] = useState(false);

  const navigate = useNavigate();
  const { type } = useParams();
  const dispatch = useDispatch();
  useNotifier();

  const currentRoute = type === 'by-individual-week';

  useEffect(() => {
    setHasPayroll(!!(data && data.payroll));
    if (data && data.payroll) {
      setPayrollVerified(data.payroll.verified);

      // find and set user that verified day;
      const userVerified =
        data.payroll?.updatedByUser?.profile?.fullName ||
        data.payroll?.updatedByUser?.username ||
        data.payroll?.createdByUser?.profile?.fullName ||
        data.payroll?.createdByUser?.username;

      setPayrollLastActionBy(userVerified || '');

      const lastActionAt = data.payroll.updatedAt || data.payroll.createdAt;
      setPayrollLastActionAt(format(new Date(lastActionAt), dateFormatWithTime));
    }
  }, [data && data.payroll]);

  const onAddCipHours = useCallback(() => {
    setAddCipHours(true);
  }, [editMode]);

  const onCloseAddCipHours = useCallback(() => {
    setAddCipHours(false);
  }, [editMode]);

  const onEditHoursClick = useCallback(() => {
    setEditMode((prev) => !prev);
  }, [editMode]);

  const onRemoveLunchTime = useCallback(
    (data) => {
      dispatch(deleteLunchTime(data._id, data.userId, currentRoute));
    },
    [editMode]
  );

  const handlePayrollVerify = () => {
    setOpenVerifyModal(null);
    const verifyState = getPayrollVerifyState(
      date,
      data || { user: { _id: users?.current?._id } },
      globalBEDateFormat
    );
    dispatch(verifySummary(date, verifyState)).catch(() => setPayrollVerified(false));
  };

  const handlePayrollReconcile = () => {
    setOpenReconcileModal(null);
    setPayrollVerified(true);
    const reconcileState = getPayrollReconcileState(data);
    dispatch(reconcilePayroll(data.payroll._id, date, reconcileState)).catch(() =>
      setPayrollVerified(false)
    );
  };

  return (
    <div className={cs('timeclock-day-report', className)} {...props}>
      <div className={'timeclock-day-report-container'}>
        <Grid container style={{ alignItems: 'center' }}>
          <Grid container item xs={5} style={{ alignItems: 'baseline' }}>
            {editable && !kiosk && userTitle && (
              <div className={classes.userTitleContainer}>
                <span className={classes.userTitleName}>{userTitle.name}</span>
                <span className={classes.userTitleNumber}>#{userTitle.number}</span>
              </div>
            )}
            {hasPermissionsFor('timeClockFullAccess') && !kiosk && currentRoute && (
              <button className={'timeclock-day-report-edit-hours'} onClick={onEditHoursClick}>
                Edit hours
              </button>
            )}
            {hasPermissionsFor('timeClockFullAccess') && !kiosk && currentRoute && editMode && (
              <button
                style={{ marginLeft: 15 }}
                className={'timeclock-day-report-edit-hours'}
                onClick={() => {
                  setAddMoreHours(true);
                }}
              >
                + Add hours
              </button>
            )}
            {hasPermissionsFor('timeClockFullAccess') && !kiosk && currentRoute && editMode && (
              <button
                style={{ marginLeft: 15 }}
                className={'timeclock-day-report-edit-hours'}
                onClick={onAddCipHours}
              >
                + Add CIP hours
              </button>
            )}
            {hasPermissionsFor('payrollActions') &&
              hasPayroll &&
              !payrollVerified &&
              currentRoute && (
                <>
                  <button
                    className={'timeclock-day-report-reconcile'}
                    onClick={() => setOpenReconcileModal(true)}
                  >
                    Reconcile
                  </button>
                  <p className={'timeclock-day-report-edited'}>
                    Edited by: {payrollLastActionBy}, time: {payrollLastActionAt}
                  </p>
                </>
              )}
          </Grid>
          <Grid item xs={3}>
            <div className={'timeclock-day-report-date'}>
              {hasPermissionsFor('payrollActions') &&
                currentRoute &&
                // show when isn't verified or verified and shouldn't be reconciled
                (!hasPayroll || (hasPayroll && payrollVerified)) && (
                  <div className={'timeclock-day-report-checkbox'}>
                    <CustomCheckbox
                      checked={payrollVerified}
                      disabled={payrollVerified}
                      onClick={() => setOpenVerifyModal(true)}
                    />
                  </div>
                )}

              {currentRoute &&
                isValid(parse(date, globalBEDateFormat, new Date())) &&
                format(
                  parse(date, globalBEDateFormat, new Date()),
                  GLOBAL_DATE_FORMAT.fullDateWithDay
                )}
            </div>
          </Grid>
          <Grid item xs={3}>
            {hasPermissionsFor('payrollActions') && payrollVerified && currentRoute && (
              <p className={'timeclock-day-report-time'}>
                Verified by {payrollLastActionBy}, time: {payrollLastActionAt}
              </p>
            )}
          </Grid>
          <Grid item xs={1} style={{ textAlign: 'center' }}>
            {worklog && <span className={'timeclock-day-report-hours'}>Hours:</span>}
          </Grid>
        </Grid>
      </div>
      {worklog ? (
        <>
          <div className={'timeclock-day-report-type'}>Time</div>
          <Worklog
            payrollVerified={payrollVerified}
            hasPayroll={hasPayroll}
            currentRoute={currentRoute}
            payroll={data?.payroll}
            editMode={editMode}
            kiosk={kiosk}
            userTitle={userTitle}
            data={data}
            date={date}
            currentDate={currentDate}
            onChangeLunchTime={onChangeLunchTime}
            onRemoveTimeclock={onRemoveTimeclock}
            onChangeTimeclock={onChangeTimeclock}
            onRemoveLunchTime={onRemoveLunchTime}
            onCreateTimeclock={onCreateTimeclock}
            editable={editable}
            addCipHours={addCipHours}
            onCloseAddCipHours={onCloseAddCipHours}
            onEditHoursClick={onEditHoursClick}
            addMoreHours={addMoreHours}
            setAddMoreHours={setAddMoreHours}
          />
        </>
      ) : currentRoute ? (
        <NoDataPayroll
          data={{ user: users?.current }}
          currentDate={
            isValid(parse(date, globalBEDateFormat, new Date())) &&
            parse(date, globalBEDateFormat, new Date())
          }
          editMode={editMode}
          onCreateTimeclock={onCreateTimeclock}
        />
      ) : (
        <NoData />
      )}
      <DeleteModal
        title={'Verify'}
        text={'Are you sure that you have checked all the data?'}
        open={!!openVerifyModal}
        onClose={() => setOpenVerifyModal(null)}
        onCancel={() => setOpenVerifyModal(null)}
        onSubmit={handlePayrollVerify}
      />
      <DeleteModal
        title={'Reconcile'}
        text={'Are you sure all data has been reconciled?'}
        open={!!openReconcileModal}
        onClose={() => setOpenReconcileModal(null)}
        onCancel={() => setOpenReconcileModal(null)}
        onSubmit={handlePayrollReconcile}
      />
    </div>
  );
};

export default withStyles(styles)(DayReport);
