import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { Grid, TextField, Typography } from '@material-ui/core';
import { addHours } from 'date-fns';

import { isIOS } from '../../../Option';
import { createOptions } from 'helpers/createOptions';
import { getDate } from 'helpers/getDate';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { convertToArr } from 'helpers/_date-helpers';

const HoursSelectForm = ({
  classes,
  type,
  control,
  label,
  name,
  index,
  errors,
  setError,
  clearErrors,
  values,
  setValue,
}) => {
  const hours = [0, 1, 2, 3, 4, 5, 6, 7];
  const minutes = [0, 15, 30, 45];

  const [hoursOptions, minutesOptions] = useMemo(() => [hours, minutes].map((opt) => opt.map(createOptions)), []);

  const { dateFormat } = useDateSettingsFormat();
  const getTimeFormatHook = useTimeSettingsFormat();


  const changeEndDate = (hours) => {
    const [nextEndDate] = convertToArr(
      addHours(
        getDate(
          values.startDate[index],
          values.startHours[index],
          values.startMinutes[index],
          values.startAmPm?.[index],
          getTimeFormatHook
        ),
        hours,
      ),
      dateFormat,
      getTimeFormatHook
    );

    setValue(`endDate[${index}]`, nextEndDate);
  }

  const validateHours = ({ hours, minutes }) => {
    clearErrors();

    const duration = (hours >= 0 ? hours : values?.hoursHours[index]) + ((minutes >= 0 ? minutes : values?.hoursMinutes[index]) / 60)

    if (duration === 0) {
      setError(`hoursHours[${index}]`, { type: 'partial-timeoff-duration', message: 'Timeoff should be longer than 0' })
    }
  }

  const handleHoursChange = (value) => {
    changeEndDate(value + ((values?.hoursMinutes[index] || 0) / 60))
    setValue(`hoursHours[${index}]`, value);
    validateHours({ hours: value });
  }

  const handleMinutesChange = (value) => {
    changeEndDate(values?.hoursHours[index] || 0 + (value / 60))
    setValue(`hoursMinutes[${index}]`, value);
    validateHours({ minutes: value })
  }

  const error = (field) => {
    return errors.hoursHours && errors.hoursHours[index];
  };

  return (
    <Grid item xs={12} md={6} className={classes.zeroPaddingTop}>
      <label htmlFor={'hours'}>
        <Typography variant="body1" color="textSecondary">
          PTO Time:
        </Typography>
        <div className={classes.flexBox}>
          <Controller
            name={`hoursHours[${index}]`}
            control={control}
            render={({ value, onChange }) => (
              <TextField
                select
                id={`hoursHours[${index}]`}
                error={error(`hoursHours[${index}]`)}
                className={classes.textField}
                variant="outlined"
                value={value}
                onChange={(e) => {
                  const { value } = e.target;
                  onChange(value);
                  handleHoursChange(value);
                }}
                SelectProps={{
                  native: isIOS,
                }}
              >
                {hoursOptions}
              </TextField>
            )}
          />
          <Controller
            name={`hoursMinutes[${index}]`}
            control={control}
            render={({ value, onChange }) => (
              <TextField
                select
                id={`hoursMinutes[${index}]`}
                error={error(`hoursMinutes[${index}]`)}
                className={classes.textField}
                variant="outlined"
                value={value}
                onChange={(e) => {
                  const { value } = e.target;
                  onChange(value);
                  handleMinutesChange(value);
                }}
                SelectProps={{
                  native: isIOS,
                }}
              >
                {minutesOptions}
              </TextField>
            )}
          />
        </div>
        {errors.hoursHours && errors.hoursHours[index] ? (
          <div className={classes.error}>{errors.hoursHours[index].message}</div>
        ) : null}
      </label>
    </Grid>
  );
};

export default HoursSelectForm;
