import { Typography, withStyles } from '@material-ui/core';
export const styles = (theme) => ({
  root: {
    margin: '0 0 20px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  cipProjItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      border: '1px solid #d9dae3',
      borderRadius: '4px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: '16px',
    },
    '@media print': {
      alignItems: 'center',
      border: 'none',
      flexDirection: 'row',
      marginBottom: '0px',
    }
  },
  cipBlockItemTitle: {
    flexBasis: '33%',
    maxWidth: '33%',
    [theme.breakpoints.down('md')]: {
      padding: '14px 0px 0px',
      flexBasis: 'auto',
      maxWidth: 'none',
      borderBottom: '1px solid #e8e8e870',
      margin: '0 16px',
      paddingBottom: '11px',
      minWidth: '90%',
    },
    '@media print': {
      flexBasis: '33%',
      maxWidth: '33%',
      padding: '0px',
      borderBottom: 'none',
      margin: '0',
      paddingBottom: '0',
      minWidth: 'auto',

    }
  },
  cipBlockItemHours: {
    flexBasis: '33%',
    maxWidth: '33%',
    [theme.breakpoints.down('md')]: {
      padding: '10px 16px 0px',
      flexBasis: 'auto',
      maxWidth: 'none',
    },
    '@media print': {
      flexBasis: '33%',
      maxWidth: '33%',
      padding: '0px',
    }
  },
  cipBlockItemPrice: {
    position: 'relative',
    flexBasis: '33%',
    maxWidth: '33%',
    [theme.breakpoints.down('md')]: {
      flexBasis: 'auto',
      maxWidth: 'none',
      margin: '0 auto 10px',
    },
    '@media print': {
      flexBasis: '33%',
      maxWidth: '33%',
      margin: '0',
    }
  },
  cipBlockItemPriceWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      flexBasis: 'auto',
      maxWidth: 'none',
      margin: '0 auto 10px',
    },
    '@media print': {
      flexGrow: '0',
      maxWidth: '25%',
      flexBasis: '25%',
      margin: '0',
    }
  },
  cipBlockItemHoursDate: {
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      margin: '10px 0 15px',
      minHeight: '45px',
    },
    '@media print': {
      display: 'block',
      margin: 0,
      minHeight: 'auto',
    }
  },
  timeSectionTimeWord: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'inline-block',
      marginLeft: 10,
      color: '#202020',
      fontSize: 16,
      textAlign: 'right',
      fontWeight: 500,
      letterSpacing: 0.25,
      marginBottom: '0.25rem',
      fontFamily: 'Poppins,Open Sans,sans-serif',
    },
    '@media print': {
      display: 'none',
    }
  },
  cipTotalTime: {
    display: 'flex',
    flexBasis: '33%',
    minWidth: '33%',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
});

// export const Root = withStyles({
//   root: {
//     margin: '0 0 20px',
//     '&:last-child': {
//       marginBottom: 0,
//     },
//   },
// })(Box);

export const Name = withStyles({
  body1: {
    color: '#202020',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '25px',
  },
})(Typography);
