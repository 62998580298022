import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { parse, format, addHours } from 'date-fns';
import TimeoffDetails from './components/TimeoffDetails';
import PageLoader from '../PageLoader';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import { getClientTzOffset } from '../../helpers/_helpers';
import styles from './styles';
import { useForm } from 'react-hook-form';
import { getTimeZone } from 'helpers/getTimeZone';
import { reasons, relations } from '../Kiosk/components/AddTimeOff/helpers/helpers';
import { useSelector, useDispatch } from 'react-redux';
import { getKioskOptions, getOptions } from 'helpers/getOptions';
import axios from 'axios';
import { getTimeoffs } from 'store/availability/availabilityPagesOperations';
import { useWeekStart } from 'hooks/useWeekStart';
import {
  globalBEDateFormat,
  DAY_PART,
  convertTimeObject,
  convertTo24HourFormat,
} from 'common/dateFormatConfig';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { useTimeSettingsFormat } from 'common/useTimeSettingsFormat';
import { useSettingsDefaultTime } from 'common/useSettingsDefaultTime';
import { convertToArr } from 'helpers/_date-helpers';
import { getDate } from 'helpers/getDate';

const EditTimeoffForm = ({
  authorizedUser,
  timeoff,
  openSnackbar,
  closeDrawer,
  changeTimeoffs,
  setNewPto,
  classes,
  closeRemove,
  closeEdit,
  isAdminPage,
  remoteUser,
  isCalendar,
}) => {
  const { dateFormat } = useDateSettingsFormat();
  const getTimeFormatHook = useTimeSettingsFormat();
  const is12Format = getTimeFormatHook().is12Format;
  const { defaultDayTime } = useSettingsDefaultTime();

  const {
    control,
    getValues,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
    trigger,
    // reset,
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      notes: timeoff.notes || '',
      isPTO: timeoff.isPTO,
      startDate: [
        timeoff?.startDate &&
          format(parse(timeoff.startDate, globalBEDateFormat, new Date()), dateFormat),
      ],
      endDate: [
        timeoff?.endDate &&
          format(parse(timeoff.endDate, globalBEDateFormat, new Date()), dateFormat),
      ],
      type: timeoff.type,
      reason: timeoff.reason,
      relationship: timeoff.relationship || '',
      startHours: [
        (timeoff?.startTime?.hour &&
          String(Number(convertTimeObject(timeoff?.startTime, is12Format).hour))) ||
          String(Number(convertTimeObject(defaultDayTime, is12Format).hour)) || 
          '8',
      ],
      startMinutes: [
        (timeoff?.startTime?.minute && convertTimeObject(timeoff?.startTime, is12Format).minute) ||
        String(Number(convertTimeObject(defaultDayTime, is12Format).minute)) || 
          '00',
      ],
      startAmPm: [
        (timeoff?.startTime?.hour && convertTimeObject(timeoff?.startTime, is12Format).amPm) ||
        String(Number(convertTimeObject(defaultDayTime, is12Format).amPm)) || 
          DAY_PART[0],
      ],
      endHours: [
        (timeoff.endTime?.hour &&
          String(Number(convertTimeObject(timeoff?.endTime, is12Format).hour))) ||
          `${((+convertTimeObject(defaultDayTime, is12Format).hour) + 8) % 12 || 12}` ||
          '4',
      ],
      endMinutes: [
        (timeoff?.endTime?.minute && convertTimeObject(timeoff?.endTime, is12Format).minute) ||
        String(Number(convertTimeObject(defaultDayTime, is12Format).minute)) || 
          '00',
      ],
      endAmPm: [
        (timeoff?.endTime?.hour && convertTimeObject(timeoff?.endTime, is12Format).amPm) ||
        String(Number(((+convertTimeObject(defaultDayTime, is12Format).hour) + 8) > 12)) || 
          DAY_PART[1],
      ],
      hoursHours: [Math.floor(timeoff.hours || 4)],
      hoursMinutes: [(Math.floor(timeoff.hours  * 60) % 60) || 0],
    },
  });
  const dispatch = useDispatch();
  const allUsers = useSelector((store) => store.availability.users);
  const storeUser = useSelector((store) => store.personalProfile.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmDialogShown, setConfirmDialogShown] = useState(null);
  const values = watch();
  const { weekDayFromO } = useWeekStart();

  useEffect(() => {
    const user = allUsers?.find((user) => user._id === timeoff.userId);
    if (!user && authorizedUser) return setUser(authorizedUser);
    if (!user && !authorizedUser && remoteUser) return setUser(remoteUser);
    setUser(user);
  }, [storeUser, allUsers]);

  useEffect(() => {
    values.reason === reasons[2] ? setValue('relationship', '') : trigger('notes');
    setValue('notes', '');
  }, [values.reason === reasons[2]]);

  useEffect(() => {
    if (values.reason === reasons[2] && values.relationship !== relations[relations.length - 1]) {
      setValue('isPTO', true);
    }
  }, [values.relationship]);

  const calcUserWeekends = () => {
    if (!user) {
      return [];
    }

    const userWorkDays = user.profile.shifts.weekDays;
    switch (userWorkDays) {
      case 'Sun-Thu':
        return [5, 6];
      case 'Mon-Fri':
        return [0, 6];
      case 'Tue-Sat':
        return [0, 1];
      default:
        return [];
    }
  };

  const onRemoveClick = () => {
    setIsModalOpen(true);
  };

  const onRemove = () => {
    const apiCall = async () => {
      try {
        const res = await axios.delete(
          `${process.env.REACT_APP_BASE_URL}/kiosk/timeoffs/${timeoff._id}`,
          getKioskOptions()
        );

        changeTimeoffs('delete', timeoff._id);
        setNewPto(res.summaryPTOHours);
        openSnackbar('success', 'The Time off was deleted successfully');
        closeDrawer();
      } catch (err) {
        openSnackbar('error', err.message);
      }
    };

    const apiCalendarCall = async () => {
      try {
        await axios.delete(
          `${process.env.REACT_APP_BASE_URL}/timeoffs/${timeoff._id}`,
          getOptions()
        );
        openSnackbar('success', 'The Time off was deleted successfully');
        closeDrawer();
        closeRemove();
      } catch (err) {
        openSnackbar('error', err?.response?.data?.message || err.message);
        return;
      }
    };

    if (!changeTimeoffs) {
      //*Calendar
      apiCalendarCall();
    } else {
      //*Kiosk
      apiCall();
    }
  };

  const closeModal = () => setIsModalOpen(false);

  const closeConfirmDialog = () => setConfirmDialogShown(null);
  const handleConfirmSubmit = () => {
    onSubmit(getValues());
    closeConfirmDialog();
  };

  const onSubmit = (values) => {
    const data = {
      isPTO: values.isPTO,
      startDate: values.startDate[0],
      endDate: values.endDate[0],
      notes: values.notes,
      type: values.type,
      reason: values.reason,
    };
    if (values.reason === reasons[2]) {
      data.relationship = values.relationship;
      data.relativeName = values.notes;
      delete data.notes;
    }

    let endDate, endHours, endMinutes, endAmPm;
    if (values.type === 'partial') {
      const hours = values.hoursHours[0] + (values.hoursMinutes[0] / 60);
      const [calculatedEndDate, calculatedEndHours, calculatedEndAmPm] = convertToArr(
        addHours(
          getDate(
            values.startDate[0],
            values.startHours[0],
            values.startMinutes[0],
            values.startAmPm?.[0],
            getTimeFormatHook
          ),
          hours,
        ),
        dateFormat,
        getTimeFormatHook
      );
      endHours = `${calculatedEndHours}`.padStart(2, '0')
      endMinutes = `${(+values.startMinutes[0] + +values.hoursMinutes[0]) % 60}`.padStart(2, '0');
      endAmPm = calculatedEndAmPm;

      data.hours = hours;
    } else {
      endHours = values?.endHours[0];
      endMinutes = values?.endMinutes[0];
      endAmPm = values?.endAmPm[0];
    }

    data.startTime = {
      hour: convertTo24HourFormat(values.startHours[0], values.startAmPm?.[0]),
      minute: values.startMinutes[0],
    };
    data.endTime = {
      hour: convertTo24HourFormat(endHours, endAmPm),
      minute: endMinutes,
    };
    const startHoursObj = [
      data.startDate,
      values.startHours[0],
      values.startMinutes[0],
      values.startAmPm?.[0] || null,
    ];
    data.utcStartDate = parse(
      getTimeFormatHook(startHoursObj).formattedTimeDate,
      getTimeFormatHook(startHoursObj).formatForDateTimePars,
      new Date()
    );
    const endHoursObj = [
      data.endDate,
      endHours,
      endMinutes,
      endAmPm,
    ];

    data.utcEndDate = parse(
      getTimeFormatHook(endHoursObj).formattedTimeDate,
      getTimeFormatHook(endHoursObj).formatForDateTimePars,
      new Date()
    );
    data.isForcePTO = !!confirmDialogShown;

    const apiCalendarCall = async () => {
      try {
        await axios.put(
          `${process.env.REACT_APP_BASE_URL}/timeoffs/${timeoff._id}`,
          data,
          getOptions()
        );
        closeEdit();
        dispatch(getTimeoffs(null, null, weekDayFromO));
      } catch (e) {
        if (e.response?.data?.type === 'not-enough-pto-hours') {
          return setConfirmDialogShown(e.response.data.message);
        }
        openSnackbar('error', e?.response?.data?.message || e.message);
        setIsSubmitting(false);
      }
    };

    const apiCall = async () => {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_BASE_URL}/kiosk/timeoffs/${timeoff._id}`,
          data,
          getKioskOptions()
        );

        if (response && response.error === 'not-enough-pto-hours')
          return setConfirmDialogShown(response.message);
        changeTimeoffs && changeTimeoffs('updateTimeoff', timeoff._id, response.data.timeoff);
        setNewPto && setNewPto(response.data.timeoffSummary);
        openSnackbar('success', 'The Time off has been updated');
        closeEdit(timeoff._id, response.data.timeoff);
      } catch (e) {
        openSnackbar('error', e?.response?.data?.message || e.message);
        setIsSubmitting(false);
      }
    };
    isCalendar ? apiCalendarCall() : apiCall();
  };

  return (
    <div className={classes.formWrapper} role="presentation">
      <PageLoader loading={isSubmitting}>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.formFlex}>
          <div>
            <Typography variant="h3" style={{ marginBottom: '30px' }}>
              Edit Timeoff Request
            </Typography>

            <IconButton
              className={classes.closeButton}
              onClick={closeDrawer}
              aria-label="Close"
              disableTouchRipple
            >
              <CloseIcon />
            </IconButton>

            {user && (
              <TimeoffDetails
                control={control}
                setValue={setValue}
                clearErrors={clearErrors}
                setError={setError}
                errors={errors}
                values={values}
                userWeekends={calcUserWeekends()}
                user={user}
                onRemove={onRemoveClick}
              />
            )}
          </div>

          <div className={classes.buttonHolder}>
            <Button
              color="secondary"
              variant="outlined"
              className={classes.cancelButton}
              onClick={closeDrawer}
              disabled={isSubmitting}
              disableTouchRipple
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="outlined"
              className={classes.saveButton}
              disabled={isSubmitting || !!Object.keys(errors).length}
              disableTouchRipple
            >
              Save
            </Button>
          </div>
        </form>
      </PageLoader>
      <ConfirmDialog
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={onRemove}
        text="Are sure you want to delete this timeoff?"
      />
      {!!confirmDialogShown && (
        <ConfirmDialog
          onClose={closeConfirmDialog}
          text={
            <span>
              {`${confirmDialogShown}. Please, confirm to proceed`.split('. ').map((line) => (
                <>
                  <span key={line}>{line}.</span> <br />
                </>
              ))}
            </span>
          }
          isOpen
          onSubmit={handleConfirmSubmit}
        />
      )}
    </div>
  );
};

EditTimeoffForm.propTypes = {
  classes: propTypes.object.isRequired,
  closeDrawer: propTypes.func.isRequired,
  closeEdit: propTypes.func.isRequired,
  closeRemove: propTypes.func.isRequired,
  timeoff: propTypes.object.isRequired,
  openSnackbar: propTypes.func.isRequired,
};

export default withStyles(styles)(EditTimeoffForm);
