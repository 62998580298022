import $api from 'http/index';
import cipPageActions from './cipPageActions';
import { getOptions } from '../../helpers/getOptions';
import { format } from 'date-fns';
import { downloadFile } from 'helpers/downloadFile';
import { globalBEDateFormat } from 'common/dateFormatConfig';
import timeclocksPageActions from 'store/timeclocks/timeclocksPageActions';

export const getCipProjects =
  (filters = {}) =>
  async (dispatch) => {
    const options = {
      ...getOptions(),
      params: {},
    };

    if (filters && Object.keys(filters).length) {
      if (filters.active) options.params.active = filters.active;
      if (filters.searchValue) options.params.searchValue = filters.searchValue;
    }

    try {
      const res = await $api.get(`${process.env.REACT_APP_BASE_URL}/cip-projects`, options);
      dispatch(cipPageActions.setCipProjects(res.data));
    } catch (e) {
      console.log(e);
    }
  };

export const removeCipProjects = (cipProjectId) => async (dispatch) => {
  await $api.delete(`${process.env.REACT_APP_BASE_URL}/cip-projects/${cipProjectId}`, getOptions());
  dispatch(cipPageActions.deleteCipProjects(cipProjectId));
};

export const toggleCipProjects = (cipProjectId, data) => async (dispatch) => {
  await $api.patch(
    `${process.env.REACT_APP_BASE_URL}/cip-projects/${cipProjectId}/status
    `,
    {
      active: data,
    },
    getOptions()
  );
  dispatch(cipPageActions.toggleActivateCipProjects(cipProjectId));
};

export const createCipProjects = (data) => async (dispatch) => {
  const res = await $api.post(`${process.env.REACT_APP_BASE_URL}/cip-projects`, data, getOptions());

  dispatch(cipPageActions.addCipProject(res.data));
};

export const updateCipProjects = (data) => async (dispatch, getState) => {
  const res = await $api.put(
    `${process.env.REACT_APP_BASE_URL}/cip-projects/${data._id}`,
    data,
    getOptions()
  );

  const store = getState().cip.projects;
  const updatedData = store.data.map((el) => {
    if (el._id === data._id) {
      return res.data;
    }
    return el;
  });

  dispatch(cipPageActions.setCipProjects(updatedData));
};

export const exportExcel = () => async (dispatch, getState) => {
  const state = getState().cip;

  const startDate = state.hours.exported.period.from;
  const endDate = state.hours.exported.period.to;

  const searchParams = new URLSearchParams();
  searchParams.append('startDate', format(startDate, globalBEDateFormat));
  searchParams.append('endDate', format(endDate, globalBEDateFormat));
  searchParams.append('cipOnly', 'true');

  const response = await $api.get(
    `${process.env.REACT_APP_BASE_URL}/timeclock-summary/generate-excel`,
    {
      params: searchParams,
      ...getOptions('text/xlsx'),
      responseType: 'blob',
    }
  );

  downloadFile(
    response.data,
    `CIP ${format(startDate, 'M-d-yy')}_${format(endDate, 'M-d-yy')}.xlsx`
  );

  dispatch(cipPageActions.setHours({ ...state.hours, exported: null }));
};

export const getTimeclockSummary =
  (date = new Date(), cipOnly) =>
  async (dispatch, getState) => {
    const state = getState().cip;

    const options = {
      ...getOptions(),
      params: {},
    };

    options.params.date = format(date, globalBEDateFormat);
    if (cipOnly) options.params.cipOnly = 'true';

    try {
      const res = await $api.get(
        `${process.env.REACT_APP_BASE_URL}/timeclock-summary/all`,
        options
      );
      dispatch(cipPageActions.setHours({ ...state.hours, data: res.data }));
    } catch (error) {
      dispatch(cipPageActions.setHours({ ...state.hours, data: [] }));
      console.log(error);
    } finally {
      dispatch(cipPageActions.setDisabled(false));
    }
  };


// ********** TIMECLOCK CIP HOURS ************

export const createTimeclockCipHours = (date, body) => async (dispatch) => {
  try {
    const options = getOptions();
    const res = await $api.post(`${process.env.REACT_APP_BASE_URL}/cip-hours`, body, options);
    
    dispatch(timeclocksPageActions.setForceUpdate(true));
  } catch (error) {
    dispatch(timeclocksPageActions.enqueueSnackbar({
      message: 'Error during creation CIP hours',
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'error',
      }
    }))
  }
}

export const updateTimeclockCipHours = (data) => async (dispatch) => {
  for (const cipItem of data) {
    try {
      const options = getOptions();
  
      const res = await $api.put(`${process.env.REACT_APP_BASE_URL}/cip-hours/${cipItem._id}`, cipItem, options);
      
      dispatch(timeclocksPageActions.setForceUpdate(true));
    } catch (error) {
      dispatch(timeclocksPageActions.enqueueSnackbar({
        message: 'Error during update CIP hour',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        }
      }))
    }
  }
}

export const deleteTimeclockCipHours = (date, cipProjectId, cipHours) => async (dispatch) => {
  try {
    for (const cipItem of cipHours) {
      const options = getOptions();
      const res = await $api.delete(`${process.env.REACT_APP_BASE_URL}/cip-hours/${cipItem?._id}`, options);
    }
    
    dispatch(timeclocksPageActions.removeCipJob({ date, cipProjectId }));
  } catch (error) {
    dispatch(timeclocksPageActions.enqueueSnackbar({
      message: 'Error during delete CIP hour',
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'error',
      }
    }))
  }
}