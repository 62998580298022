import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { ExpandMore } from '@material-ui/icons';
import { isIOS, Option } from 'components/Option';

export const CipField = (props) => {
  const { id, field, options = [], label, style = {}, disabled, renderEmpty } = props;

  const renderOptions = options.map((value, index) => (
    <Option key={`${id}[${index}]`} value={value?.value}>
      {value?.label || value?.value}
    </Option>
  ));

  return (
    <div style={{ ...style }}>
      <label htmlFor={id}>
        <Typography variant="body1" color="textSecondary">{label}</Typography>
        <TextField
          select
          id={id}
          native={isIOS}
          fullWidth={true}
          IconComponent={ExpandMore}
          variant="outlined"
          {...field}
          disabled={disabled}
          inputProps={{ 'aria-label': label }}
        >
          {renderEmpty && (
            <Option value={null}>
              -
            </Option>
          )}
          {renderOptions}
        </TextField>
      </label>
    </div>
  );
};

export default CipField;

